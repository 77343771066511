<template>
  <v-app id="app">
    <div class="main-div">
      <div v-if="$router.currentRoute.path !== '/login' && !$router.currentRoute.path.includes('/signup')" class="topBar">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up" style="color: white"></v-app-bar-nav-icon>

        <img class="logo" src="https://parabellum.si/wp-content/uploads/2021/01/logoWW.png">

        <div class="topBarButtons">
          <a @click="logOut()"><v-icon style="color: white">mdi-logout</v-icon><br>Odjava</a>
          <router-link to="/member"><v-icon style="color: white">mdi-account</v-icon><br>Profil</router-link>

        </div>

      </div>

      <v-navigation-drawer
          v-model="drawer"
          temporary
          absolute
          width = "300"
          class = "drawer"
          dark
      >
        <img src="https://parabellum.si/wp-content/uploads/2023/02/logoW.png" alt="PARABELLUM" class="logo-mobile"/>
        <router-link to="/member"><v-icon style="color: white">mdi-account</v-icon><br>Profil</router-link>
        <router-link to="/faq"><v-icon style="color: white">mdi-frequently-asked-questions</v-icon><br>Vprašanja</router-link>
      </v-navigation-drawer>

      <v-main>
        <router-view/>
      </v-main>

    </div>

  </v-app>
</template>

<script>
  export default {
    name: 'App',
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
    },
    data() {
      return {
        drawer: false,
      }
    },
    methods: {
      logOut() {
        this.$store.dispatch('auth/logout');
        this.$router.go('/login');
      },
    }
  }
</script>

<style scoped>
    .topBar{
        height: 100px;
        background-color: #656565;
        padding-right: 50px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 50px;
    }
    .topBar a {
      display: block;
      color: #eeeeee;
      text-align: center;
      padding: 12px;
      width: 120px;
      height: 100%;
      float: right;
      text-decoration: none;
      font-size: 18px;
      border-radius: 5px;
      margin-left: 10px;
      transition: 1s;
    }
    .topBar a:hover:not(.active) {
      background-color: rgba(217, 217, 217, 0.3);
      color: white;
      transition: 0.1s;
    }
    .topBarButtons{
        float: right;
    }
    .router-link-active {
      background: #ff6000;
      border-radius: 5px;
    }
    .logo{
      height: 50px;
      margin: 0;
      margin-top: 15px;
    }

    .logo-mobile {
      margin-left: 100px;
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100px;
    }

    .drawer a {
      display: block;
      color: #eeeeee;
      text-align: center;
      padding: 12px;
      margin-left: 15px;
      width: 90%;
      height: 85px;
      text-decoration: none;
      font-size: 18px;
      border-radius: 40px;
      transition: 1s;
    }
    #app{
      background-image: linear-gradient(45deg, #7d7d7d, black);
    }


    @media only screen and (max-width: 960px){
      .topBar{
        height: 60px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 10px;
      }
      .topBarButtons{
        display: none;
      }
      .logo{
        height: 100%;
        margin: auto;
        float: right;
      }
    }


</style>

import axios from 'axios';
import authHeader from "../headers/auth-header";
import fileUploadAuthHeader from "../headers/file-upload-auth-header";
import EndpointService from "./endpoints.service"
import {REQUEST_METHODS} from "@/models/request-methods";


const API_URL = EndpointService.getApiV1Endpoint();

class ApiConnector {

    executeCall(apiRequest){

        if(apiRequest.requestMethod === REQUEST_METHODS.GET){
            return axios.get(API_URL + apiRequest.urlPath, { headers: authHeader() })
        }
        else if(apiRequest.requestMethod === REQUEST_METHODS.POST){
            return axios.post(API_URL + apiRequest.urlPath, apiRequest.body, { headers: authHeader() })
        }
        else if(apiRequest.requestMethod === REQUEST_METHODS.PUT){
            return axios.put(API_URL + apiRequest.urlPath, apiRequest.body, { headers: authHeader() })
        }
        else if(apiRequest.requestMethod === REQUEST_METHODS.DELETE){
            return axios.delete(API_URL + apiRequest.urlPath, { headers: authHeader() })
        }
        else if(apiRequest.requestMethod === REQUEST_METHODS.UPLOAD_FILE){
            let formData = new FormData()
            formData.append("file", apiRequest.body)
            return axios.post(API_URL + apiRequest.urlPath,
                formData,
                {
                    headers: {
                        Authorization: fileUploadAuthHeader(),
                        Accept: "application/json" ,
                        "Content-Type": "multipart/form-data; boundary=${formData._boundary}"
                    }
                }
            )
        }
        else {
            console.log("Unsupported method in API request: " + apiRequest.requestMethod)
        }

    }

}

export default new ApiConnector();

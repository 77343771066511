import Vue from 'vue'
import App from './App.vue'
import { router } from './router';
import titleMixin from "@/mixins/titleMixin";
import vuetify from './plugins/vuetify';
import store from './store';

Vue.mixin(titleMixin);

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')



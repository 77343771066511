import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const router = new Router({
    //mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('./views/login.vue'),
        },
        {
            path: '/login',
            component: () => import('./views/login.vue'),
        },
        {
            path: '/member',
            component: () => import('./views/member.vue'),
        },
        {
            path: '/signup/:id',
            component: () => import('./views/signup.vue'),
        }
    ]
});

/*router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/home'];
    let authRequired = !publicPages.includes(to.path);
    if (authRequired)
        authRequired = !to.path.includes("/showqr/");
    if (authRequired)
        authRequired = !to.path.includes("/reset/");
    const loggedIn = localStorage.getItem('user')
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});*/

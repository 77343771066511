

export const REQUEST_METHODS = {
    POST: "post",
    GET: "get",
    PUT: "put",
    DELETE: "delete",
    UPLOAD_FILE: "upload_file"
};

class RequestMethods {}

export default new RequestMethods();

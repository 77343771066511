
import { REQUEST_METHODS } from "./request-methods";

export default class ApiRequest {

    constructor(urlPath, requestMethod, body) {
        this.urlPath = urlPath;
        this.requestMethod = requestMethod
        this.body = body
    }

    static BuildWithURL(urlPath){
        return new ApiRequest(urlPath, REQUEST_METHODS.GET, {});
    }

    static BuildWithURLAndMethod(urlPath, requestMethod){
        return new ApiRequest(urlPath, requestMethod, {});
    }

    static BuildWithURLAndMethodAndBody(urlPath, requestMethod, body){
        return new ApiRequest(urlPath, requestMethod, body);
    }

    static BuildWithURLForFileUpload(urlPath, fileData){
        return new ApiRequest(urlPath, REQUEST_METHODS.POST, fileData);
    }

}

import ApiConnector from "./api-connector.service"
import ApiRequest from "@/models/api-request";
import {REQUEST_METHODS} from "@/models/request-methods";


class AuthService {
    login(userCredentials) {
        return ApiConnector.executeCall(ApiRequest.BuildWithURLAndMethodAndBody("authenticate/login", REQUEST_METHODS.POST, userCredentials))
    }
    logout() {
        return ApiConnector.executeCall(ApiRequest.BuildWithURL("authenticate/logout")).then(
            localStorage.removeItem('user')
        )
    }
    check() {
        return ApiConnector.executeCall(ApiRequest.BuildWithURL("authenticate/check"))
    }
}

export default new AuthService();
